.form-signin {
  text-align: center;
  margin: 0 auto;

  h2{
    font-size: 1.5em;
  }

  h2,
  .checkbox {
    margin-bottom: 15px;
  }
  .checkbox {
    font-weight: normal;
  }
  .form-control {
    position: relative;
    height: auto;
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
    padding: 10px;
    font-size: 16px;
  }
  .form-control:focus {
    z-index: 2;
  }
  .text-danger{
    display: block;
    text-align: left;
    color: #fff;
    margin: 0px 5px 15px 5px;
  }

  .field-login {
    margin-bottom: -1px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .field-password {
    margin-bottom: 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

.app-links{
  clear: both;
  width: 100%;
  text-align: center;

  h5{
    color: #eee;
    margin-bottom: 15px;
  }

  .soon{
    opacity: 0.5 !important;
  }

  a{
    display: inline-block;
    margin: 0px 5px 15px;
    opacity: 0.85;
    padding: 0;

    &:hover{
      opacity: 1;
    }

    img{
      margin: 0;
      height: 50px;
      width: auto;
    }
  }
  margin: 150px 0 50px;
}
