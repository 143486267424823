/* Styles */
html, body {
  height: 100%;
}

.ant-card-body {
  & > div > a {
    color: #0a4f7a !important;
  }
}

body {
  font-size: 14px;
  line-height: 1.4em;
  font-family: 'Open Sans', sans-serif;
  color: #4d4d4d;
  margin: 0 auto;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  font-smoothing: antialiased;
  font-weight: 400;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}

@-moz-document url-prefix() {
  body {
    font-weight: lighter !important;
  }
}

@-moz-document url-prefix() {
  body {
    font-weight: lighter !important;
  }
}

a:hover,
a:active,
a:focus {
  cursor: pointer;
  //text-decoration: none;
}

a[disabled] {
  pointer-events: none;
}

/* .checkbox input[type="checkbox"]{
  margin-left: 0;
} */
label {
  font-size: 0.9em;
}

textarea,
select[multiple] {
  height: 120px;
  resize: vertical;
}

input.options-filter {
  margin-bottom: 5px !important;
  width: 50%;
  height: 23px;
  font-size: 12px;
}

#root {
  height: 100%;
}

.letter-container canvas {
  border: 1px solid #eee;
  margin-bottom: 15px;
}

.modal-90W {
  width: 90%;
  max-width: none !important;
}

.refresh-results {
  padding-top: 6px;

  a {
    color: #666;
    font-size: 0.9em;

    &.disabled {
      opacity: 0.5;
      pointer-events: none;
    }

    &:hover {
      color: #0a4f7a;
      cursor: pointer;
      text-decoration: none;
    }
  }
}

.form-group {
  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  margin-right: 0px !important;
}

.step-info {
  margin-bottom: 40px;
  background: #fafdff;
  border-bottom: 1px solid #cde7fb;
  border-top: 1px solid #cde7fb;
  padding: 15px;
  color: #3c89c5;
}

.hide-input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;

  & + label {
    cursor: pointer;
  }
}

.stronger {
  font-weight: 600;
}

.break-word {
  padding-right: 10px;
  word-break: break-word;
}

.lowercase {
  text-transform: lowercase;
}

.loader {
  position: absolute;
  left: 0;
  top: 0;
  background-color: rgba(228, 235, 241, 0.45);
  text-align: center;
  width: 100%;
  height: 100%;
  z-index: 2;
  font-size: 14px;
  font-weight: 600;
  color: #3f617f;
}

.documents-tab {
  a {
    font-size: 14px;
    display: inline-block;
    margin: 5px 0px 5px 3px;
  }
}

.doo-combo {
  width: 100%;
  position: absolute;
  right: 15px;


  .btn {
    font-weight: 600 !important;
  }

  .btn-success {
    min-width: 85% !important;
    line-height: 18px;
    box-shadow: none;
  }

  .dropdown-toggle {
    width: 15% !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin: 0 !important;
    box-shadow: none;
  }

  .dropdown-menu {
    padding: 0;
    z-index: 3;
    border: none;
    width: 100%;
    margin-top: -1px;

    .btn {
      border-radius: 0 0 3px 3px;
    }
  }
}

.delete-icon {
  cursor: pointer;
  opacity: 0.7;
  margin-left: 5px;

  &:hover {
    opacity: 1;
  }
}

.user-form {
  [name="entityIds"] {
    height: 200px;
  }
}

#pageDropDown {
  padding: 5px;
  border-color: #ddd;
  background: transparent;
  color: #333;
  margin-left: 10px;
}

h1.sticky {
  background: $sticky-bg-default;
  font-size: $menu-item-font-size;
  color: $sticky-color;
  font-weight: 400;
  padding: 10px;
  margin: 0 0 25px;
  box-shadow: 0px 1px 4px -2px black;

  &.create {
    background: $sticky-bg-create;
    color: $sticky-color;
  }

  &.edit {
    background: $sticky-bg-edit;
    color: $sticky-color;
  }

  .container {
    padding-left: 25px;
  }
}

h3 {
  font-size: 17px;
  margin: 10px 0;
  padding: 0;
  // color: $sticky-color;
}

h5 {
  font-size: 14px;
  margin: 10px 0;
  padding: 0;
  color: $sticky-color;
}

.normal-border-radius {
  border-radius: 3px !important;
}

.capitalize {
  text-transform: capitalize;
}

table {
  .godoo-wo-id,
  .godoo-process-id {
    display: block;
    width: auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .godoo-process-id {
    width: 125px;
  }
}

label {
  font-weight: normal;
  margin-top: 5px;
  margin-bottom: 2px;
}

.beamerImage {
  z-index: 0 !important;
  filter: invert(19%) sepia(23%) saturate(6436%) hue-rotate(184deg) brightness(94%) contrast(92%);
}

#beamerSelector {
  display: none !important;
}

.mandatory-text {
  font-size: 13px;
}

footer {
  text-align: center;
  color: #444;
  padding: 75px 20px 20px;
  clear: both;

  ul {
    display: block;
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      margin: 0;
      padding: 2px 15px;

      span {
        font-weight: 600;
      }
    }
  }

  a,
  a:hover,
  a:active {
    color: #555;
  }

  a:hover {
    text-decoration: underline;
  }

}

.pagination-info {
  .dropdown {
    margin-left: 5px;
    box-shadow: none;
  }

  #pageSize {
    padding-left: 8px;
    padding-right: 8px;
  }
}

.apply-template {
  select,
  button,
  label {
    margin-left: 10px;
  }
}

.layout {
  height: 100%;
}

.logo {
  margin: auto;
  max-width: min(70%, 150px);
  max-height: 300px;
  object-fit: contain;
}

.logoContainer {
  display: flex;
  padding: 12px;
}

.classic {
  background: #0d4d76;
  color: #fff;
  height: 100%;
  flex: 1;
  min-height: 500px;

  a,
  a:hover,
  a:active {
    color: #eee;
  }

  h1, h2, h3 {
    color: #fff;
  }

  footer {
    color: #fff;

    a,
    a:hover,
    a:active {
      color: #eee;
    }

  }

}


.dark {
  background: rgb(13, 77, 118);
  background: -moz-linear-gradient(180deg, rgba(13, 77, 118, 1) 0%, rgba(19, 108, 165, 1) 100%);
  background: -webkit-linear-gradient(180deg, rgba(13, 77, 118, 1) 0%, rgba(19, 108, 165, 1) 100%);
  background: linear-gradient(180deg, rgba(13, 77, 118, 1) 0%, rgba(19, 108, 165, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0d4d76", endColorstr="#136ca5", GradientType=1);

  a,
  a:hover,
  a:active {
    color: #eee;
  }

  h1, h2, h3 {
    color: #fff;
  }


  footer {
    color: #fff;

    a,
    a:hover,
    a:active {
      color: #eee;
    }

  }

  #main-content {
    min-height: 500px;
  }

}

.light {
  a,
  a:hover,
  a:active {
    color: #333;
  }

  h1, h2, h3 {
    color: #000;
  }

  footer {
    color: #000;

    a,
    a:hover,
    a:active {
      color: #333;
    }

  }

  #main-content {
    min-height: 500px;
  }

}

.gutter-top {
  margin-top: 20px;
}

.gutter-bottom {
  margin-bottom: 20px;
}

.group:after {
  content: "";
  display: table;
  clear: both;
}

.step-navigation {
  text-align: right;
  margin: 0 0 15px;

  .btn {
    margin-left: 5px;
    min-width: 30px;
  }
}

fieldset {
  position: relative;
}

legend span {
  display: block;
  position: absolute;
  top: -20px;
  left: 100px
}

.no-selection,
.no-selection * {
  -webkit-user-select: none; /* webkit (safari, chrome) browsers */
  -moz-user-select: none; /* mozilla browsers */
  -khtml-user-select: none; /* webkit (konqueror) browsers */
  -ms-user-select: none; /* IE10+ */
}

.table.small {
  border-radius: 3px;
  border: 1px solid #0a4f7a;
  border-collapse: separate;
  border-spacing: 0;

  thead {
    th {
      padding-top: 3px;
      padding-bottom: 3px;
    }
  }

  tbody {
    tr:nth-of-type(even) {
      background: #fff;
    }

    tr:nth-of-type(odd) {
      background: #d4f2ff;
    }

    tr td {
      padding-top: 3px;
      padding-bottom: 3px;
      box-shadow: 1px 0 0 0 #fff inset;
      border: none;
    }
  }
}

.group:after {
  content: "";
  display: table;
  clear: both;
}

.panel .panel-body .no-padding {
  padding: 10px;
}

.no-padding {
  padding-left: 0;
  padding-right: 0;
}

.button-group {
  > .btn + .btn {
    margin-left: 20px;
  }

  .btn + .btn-group,
  .btn-group + .btn-group {
    margin-left: 20px;
  }
}

.btn-group + .btn-group,
.btn + .btn-group,
.btn-group + .btn {
  margin-left: 20px;
}

.modal.popup-xl {
  .modal-dialog {
    width: 90% !important;
    height: 90%;

    .modal-content {
      min-height: 400px;
    }
  }
}

.modal.popup-l {
  .modal-dialog {
    width: 70% !important;
    height: 90%;
  }

  .modal-content {
    min-height: 400px;
  }
}

.modal.popup-wide {
  .modal-dialog {
    width: 50% !important;
  }

}

.modal.popup-wide-70 {
  .modal-dialog {
    width: 70% !important;
  }

}

.modal.image-preview {

  .modal-body {
    min-height: 300px;

    > img {
      width: 100%;
      max-width: 100%;
      height: auto;
    }
  }

}

.billing-disabled-quantity {
  padding-left: 13px;
}

.btn.upload-file {
  padding: 5px;
  display: table;
  position: absolute;
  top: 2px;
  right: 5px;

  .glyphicon {
    margin-right: 0;
  }
}

.btn.comment-upload-file {
  padding: 5px !important;
  margin-top: -2px;

  .glyphicon {
    margin-right: 0;
  }
}

.text-danger-wrapper {
  word-wrap: break-word;
}

.pad7px {
  padding-top: 7px;
}

.tab-content {
  .pad7px {
    padding-top: 0px;
  }
}

.appointment-details {

  &::before {
    content: "";
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 2000;
  }

  .modal-dialog {
    margin-top: 60px;
    z-index: 2001;
  }

}

.order-page {
  > .panel-body {
    min-height: 400px;
  }
}

a.order-details-toggle {
  color: #ff00ff;
  margin-right: 5px;
  font-size: 19px;
  line-height: 13px;

  &:active,
  &:focus,
  &:hover {
    text-decoration: none;
    color: #ce30ce;
  }

  span {
    vertical-align: bottom;
  }

  span + span {
    font-size: 12px;
  }
}

.discreet {
  color: #aaa;
}

.dnd-drop-area {
  width: 100%;
  padding: 15px;
  border: 2px dashed #dedede;
}

.display-list {
  list-style: none;
  display: block;
  padding: 0;
  margin: 0;

  h5 {
    display: inline-block;
  }

  &-item {
    user-select: none;
    padding: 5px;
    border: 1px solid #dedede;
    margin-bottom: 10px;
    background-color: #fefefe;

    .glyphicon {
      top: 3px;
      right: 3px;
    }

    &:hover {
      background-color: #f4f4f4;
    }
  }
}

.drag-handle + a + strong + span + aside {
  padding-left: 10px;
  color: #666;
  font-size: 12px;
}

.configuration-list {
  list-style: none;
  display: block;
  padding: 0;
  margin: 0;

  &-item {
    user-select: none;
    display: block;
    position: relative;
    padding: 10px;
    border: 1px solid #dedede;
    margin-bottom: 10px;
    background-color: #fefefe;

    .drag-handle {
      display: inline-block;
      cursor: move;
      cursor: -webkit-grab;
    }

    input[type="radio"], input[type="checkbox"] {
      margin-top: 10px;
    }

    .header {
      a {
        font-size: 12px;
      }
    }

    .content {
      margin-top: 10px;
      padding-top: 10px;
      border-top: 1px solid #e9ecf0;
      font-size: 12px;
    }
  }
}

#doo-dropdown {
  + .dropdown-menu {
    > div button {
      padding-top: 5px;
      padding-bottom: 7px;
    }

    > div + a {
      margin: -2px 0 0;
      position: relative;
      // background-color: #aaa;
      // border-color: #aaa;
    }
  }
}

.attributes-list {
  list-style: none;
  display: block;
  padding: 0;

  &-item {
    user-select: none;
    display: block;
    margin-top: 6px;

    .glyphicon-plus-sign {
      top: 3px;
      right: 3px;
    }

    > div {
      padding: 5px;
      border: 1px dashed #bcbcbc;
      line-height: 1.5em;
      font-size: 13px;

      &:hover {
        background-color: #fafafa;
        border: 1px solid #bcbcbc;
        cursor: move;
        cursor: -webkit-grab;
      }

    }

    &:first-child {
      margin-top: 0;
    }
  }
}

.report-configuration-page {
  .glyphicon-plus-sign {
    opacity: 0.7;

    &:hover {
      opacity: 1;
    }
  }

  .panel .panel {
    border-radius: 2px;

    .panel-heading {
      border-top-right-radius: 2px;
      border-top-left-radius: 3px;
      font-size: 14px;
      padding: 0;

      span.panel-title {
        padding: 10px;
        display: block;
        font-size: 15px;
        line-height: 18px;
      }

      h4 {
        font-size: 14px;

        a {
          padding: 8px 10px;
          display: block;
        }
      }
    }
  }
}

.attributes-groups {
  .panel-body {
    padding: 10px !important;
  }

  .panel-heading {
    h4 {

    }
  }
}

.user-popover-trigger {
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

span.godoo {
  color: #ff00ff;
  font-weight: 600;
}

.popover {
  border-radius: 3px;
  font-size: 12px;
  line-height: 1.5em;
  box-shadow: 2px 3px 8px -3px #555;

  &.right {
    margin-left: 15px;
  }
}

.leave-space {
  height: 15px;
}

.sort-attribues-panel {
  .panel-body {
    padding: 10px;
  }
}

.sorting-list-item {
  user-select: none;
  display: block;
  position: relative;
  border: 1px solid #dedede;
  background-color: #fefefe;
  padding: 4px 8px;
  margin-bottom: 4px;
  line-height: 23px;
  font-size: 13px;

  &--disabled {
    color: #999;
  }

  input {
    margin: -2px 5px 0 0;
  }

  select {
    margin-left: 5px;
    background: #fff;
    border-radius: 0;
    border: 1px solid #b7b7b7;
    padding: 0px;
    font-size: 11px;
    line-height: 18px;
  }

  .drag-handle {
    display: inline-block;
    cursor: move;
    cursor: -webkit-grab;
    margin-right: 5px;
    font-weight: 500;
    line-height: 13px;
    font-size: 17px;
  }

  &_position {
    float: right;
  }
}

#accordion-attributes .panel-title {
  font-size: 14px;
}

.mapping-item {
  border: 1px solid #cecece;
  padding: 15px;

  + .mapping-item {
    margin-top: 25px;
  }
}

.external-order-format {
  .form-group {
    margin-bottom: 0;
  }

  .control-label {
    padding-top: 0;
    text-align: left;
    display: flex;
    place-content: flex-end;
    height: auto;
    min-height: 45px;
    flex-direction: column;
    padding-bottom: 5px;
  }

  .text-danger {
    padding-left: 0;
    padding-right: 0;
  }

}

.live-order-id-example {
  padding-top: 6px;
}


.tabs-list-item {
  &__container {
    position: relative;

    > .panel > .panel-heading {
      > .remove-icon {
        display: none;
        color: #555;
        top: 20px;
        right: 20px;
      }

      &:hover {
        > .remove-icon {
          display: inline-block;
        }
      }
    }
  }

  .drag-handle {
    font-size: 20px;
    color: #999;

    &:hover,
    &:focus,
    &:active {
      color: #444;
      cursor: pointer;
    }
  }

  .tab-name-input {
    min-width: 300px;
    max-width: 90%;
    width: 25px;
    display: inline-block;
    margin-left: 10px;
  }

  .remove-icon {
    float: right;
    color: #ccc;
    font-weight: 300;
    font-size: 11px;
    cursor: pointer;
    top: 9px;
    position: absolute;
    right: 6px;
  }

}

.add-object-to-tab-panel {
  border: 1px solid #c3c3c3;
  box-shadow: 0px 4px 11px -5px rgba(0, 0, 0, 0.3);
  border-radius: 2px;
}


.tab-configuration-item {
  display: block;
  position: relative;
  padding: 5px 20px 5px 0;

  & + .tab-configuration-item {
    border-top: 1px solid #ececec;
  }

  &:hover {
    color: #333;

    .remove-icon {
      color: #333;
    }
  }
}

.tab-configuration-item {
  position: relative;

  .remove-icon {
    float: right;
    color: #ccc;
    font-weight: 300;
    font-size: 11px;
    cursor: pointer;
    top: 9px;
    position: absolute;
    right: 6px;
  }

  .drag-handle {
    font-size: 15px;
    color: #999;

    &:hover,
    &:focus,
    &:active {
      color: #444;
      cursor: pointer;
    }
  }
}

.process-object-pairs-management-page {
  .flex-items {
    display: flex;
    flex-wrap: wrap;
  }

  .flex-box-item {
    flex: 1 0 48%;
  }
}

.alert-warning {
  padding: 15px;
}

.no-comment-yet {
  font-style: italic;
  font-size: 12px;
  color: #999;
}

.comment-list {
  height: auto;
  max-height: 400px;
  overflow-x: hidden;
  overflow-y: auto;
}

.comment-item {
  font-size: 14px;
  white-space: pre-wrap;
  color: #555;
  margin: 0 !important;

  + .comment-item {
    border-top: 1px solid #eaeaea;
  }

  &--time, &--type, &--edited {
    font-size: 12px;
    color: #999;
  }

  &--comment-text {
    height: 130px;
  }
}

.loading-component {
  width: 0;
  height: 0;
  z-index: 99;
  text-align: center;
  background-color: rgba(0, 0, 0, 0);
  position: fixed;
  top: 0;
  left: 0;
  transition: background-color 1s;

  svg {
    width: 140px;
  }

  &.loading {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.55);
  }
}

.modal-body .report-configuration-page .container {
  width: 100%;
}

.form-control-static {
  word-wrap: break-word;
}

.stepObject {
  padding: 1%;
  border: 1px solid #bdc8cb;
  margin-bottom: 10px;
}

.btn {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.btn-group > .btn:first-child {
  max-width: 300px;
}

.comment-toggle {
  cursor: pointer;
  color: #aaa;
}

.filecollection {
  //position: absolute;
  .text-danger-wrapper {
    width: 100%;
    text-align: left;
    display: block;
  }
}

.ant-collapse-item-active.ant-collapse-custom-panel .ant-collapse-content-active {
  overflow: initial;
}

.capitalize {
  text-transform: capitalize
}

.blink {
  animation: blink-animation 1s steps(5, start) infinite;
  -webkit-animation: blink-animation 1s steps(5, start) infinite;
}

@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}

@-webkit-keyframes blink-animation {
  to {
    visibility: hidden;
  }
}

.flow_overview_tab_link {
  display: flex;

  a {
    border-left: 3px solid blue;
    display: flex;
    color: blue;

    &:hover {
      color: darkblue;
    }

    width: 100%;
    padding: 1rem;
    align-items: center;
  }
}

