@import 'variables';
// Load bootstrap pre-customizations
@import 'pre-customizations.scss';


$icon-font-path: "~bootstrap-sass/assets/fonts/bootstrap/";
@import '~bootstrap-sass/assets/stylesheets/_bootstrap.scss';
@import '~antd/dist/antd.css';
form .ant-select {
  width: 100%;
}

// Load bootstrap overwrites
@import 'main';
@import 'nprogress';
@import 'toastr';

// Components
@import 'widget';
@import 'bg-colors';

// Per page styles
@import 'sign-in';
@import 'home';

// Section styles
@import 'header';

// Responsive layouts
@import 'responsive';



