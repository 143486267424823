.page {
  &--home {
    text-align: center;
    position: relative;
    color: #757575;
    min-height: 90vh;
    font-size: 21px;
    padding-top: 200px;
    font-weight: 600;
  }
}

$deadline-progress: rgb(111, 0, 255);

.deadline-wrapper {

  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
  padding: 3px;

  .row-deadline {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
  }

  .column-deadline {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
  }

  .clock-icon {
    color: $deadline-progress;
  }

  .deadline {
    background-color: $deadline-progress;
    height: 5px;
    flex: 0.1;
  }
}

.priority-icon {
  color: black;
}




