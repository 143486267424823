.bg-red,
.bg-godoo-magenta,
.bg-yellow,
.bg-aqua,
.bg-blue,
.bg-light-blue,
.bg-green,
.bg-navy,
.bg-teal,
.bg-olive,
.bg-lime,
.bg-orange,
.bg-fuchsia,
.bg-purple,
.bg-maroon,
.bg-black,
.bg-main-menu-item,
.bg-main-menu-item-hover,
.bg-main-menu-item-active,
.bg-sub-menu-item,
.bg-sub-menu-item-hover,
.bg-sub-menu-item-active
{
  color: #e4f7fe;
}

.bg-gray {
  color: #000;
  background-color: #d2d6de;
}
.bg-gray-light {
  background-color: #f7f7f7;
}
.bg-black {
  background-color: #111111;
}
.bg-red {
  background-color: #dd4b39;
}
.bg-yellow{
  background-color: #f39c12;
}
.bg-aqua {
  background-color: #00c0ef;
}
.bg-blue {
  background-color: #0073b7;
}
.bg-light-blue {
  background-color: #3c8dbc;
}
.bg-green{
  background-color: #00a65a;
}
.bg-navy {
  background-color: #001f3f;
}
.bg-teal {
  background-color: #39cccc;
}
.bg-olive {
  background-color: #3d9970;
}
.bg-lime {
  background-color: #01ff70;
}
.bg-orange {
  background-color: #ff851b;
}
.bg-fuchsia {
  background-color: #f012be;
}
.bg-purple {
  background-color: #605ca8;
}
.bg-maroon {
  background-color: #d81b60;
}


.bg-main-menu-item{
  background-color: $main-menu-item-bg;
}
.bg-main-menu-item-hover{
  background-color: $main-menu-item-bg-hover;
}
.bg-main-menu-item-active{
  background-color: $main-menu-item-bg-active;
}

.bg-sub-menu-item{
  background-color: $sub-menu-item-bg;
}
.bg-sub-menu-item-hover{
  background-color: $sub-menu-item-bg-hover;
}
.bg-sub-menu-item-active{
  background-color: $sub-menu-item-bg-active;
}

.bg-godoo-main{ /* main blue */
  background-color: $main-menu-item-bg;
}
.bg-godoo-main-1{ /* blue active/hover */
  background-color: $main-menu-item-bg-active;
}
.bg-godoo-main-2{ /* blue submenu hover */
  background-color: $sub-menu-item-bg-hover;
}
.bg-godoo-main-3{ /* blue overview tab b */
  background-color: #e4f7fe;
}
.bg-godoo-second { /* magenta */
  background-color: #ff00ff;
}
.bg-godoo-third { /* green */
  background-color: #a8d15d;
}
