/* Mobile */
@media screen and (max-width: $screen-xs){

  .full-page .logo{
    margin-top: 100px;
    margin-bottom: 0px;
  }

  .app-links{
    margin: 85px 0 0;

    a{
      margin: 0 3px 15px;
      
      img{
        height: 45px;
      }
    }
  }

  .panel-footer{
    .btn{
      margin: 0 !important;
      width: 100%;
    }

    .btn + .btn{
      margin-top: 10px !important;
    }
  }

  .full-page {
    #main-content{
      padding-top: 0;
    }
  }

  h1.sticky{
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 10px;
  }

  #main-header{
    position: fixed;
    width: 100%;
    background: #fff;

    .logo{
      margin-left: 0;
      padding-left: 0;

      img{
        height: 40px;
      }
    }

    .right-panel{
      float: right;
      margin: 10px 0 0 0;

      .btn-group{
        display: none;
      }

      .user-name{
        display: none
      }

      .user-info{
        > a {

          margin-right:0;

          & + a{
            margin-left: 8px;
          }

          img{
            //width: 15px;
            //height: 15px;
          }
        }
      }
    }

    .main-menu{
      width: 100%;
      margin: 0;
      
      .sub-menu{
        width: 100%;
        position: fixed;
        margin: 0;
        right: 0;
      }
    }


  }

}