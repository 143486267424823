/*
 * Component: Small Box
 * --------------------
 */


.small-box {
  border-radius:2px;
  position: relative;
  display: block;
  margin-bottom: 0;
  box-shadow: $box-boxshadow;
  // content wrapper
  > .inner {
    padding: 20px 15px 0;
  }

  > .small-box-footer {
    position: relative;
    text-align: center;
    padding: 3px 0;
    color: #fff;
    color: rgba(255, 255, 255, 0.8);
    display: block;
    z-index: 1;
    background: rgba(0, 0, 0, 0.1);
    text-decoration: none;
   
  }

  h3 {
    font-size: 38px;
    font-weight: bold;
    margin: 0 0 10px 0;
    white-space: nowrap;
    padding: 0;

  }

  p {
    font-size: 15px;
    > small {
      display: block;
      color: #f9f9f9;
      font-size: 13px;
      margin-top: 5px;
    }
  }

  h3, p {
    z-index: 2;
  }

  // the icon
  .icon {
    transition: all $transition-speed linear;
    position: absolute;
    top: 30px;
    right: 10px;
    z-index: 0;
    color: rgba(0, 0, 0, 0.15);
    fill: rgba(0, 0, 0, 0.15);
  }

  // Small box hover state
  &.completed:hover {
    text-decoration: none;
    color: #f9f9f9;
    fill: #f9f9f9;
    cursor:pointer;
    opacity:0.9;
    // Animate icons on small box hover
    .icon {
      transform: scale(1.1);
    }
    .small-box-footer{
      color: #fff;
      background: rgba(0, 0, 0, 0.15);
    }
  }
}

@media (max-width: $screen-xs-max) {
  // No need for icons on very small devices
  .small-box {
    text-align: center;
    .icon {
      display: none;
    }
    p {
      font-size: 12px;
    }
  }
}

#widgets .panel.panel-info {
 transition: all 0 linear;
 border: 0;

 border-radius: 3px;
}

#widgets .panel.panel-info .collapse.in, #widgets .panel.panel-info .collapsing {
 border: 1px  solid $main-menu-item-bg;
 border-top:0;
 border-bottom-left-radius: 3px;
 border-bottom-right-radius: 3px;
}

#widgets .small-widget.opened .small-box-footer{
  background: rgba(0, 0, 0, 0.25);
  box-shadow: inset 0 2px 5px -3px black;
} 

.small-widget{
  margin-top:10px;
  position:relative;
}

.small-box.error{
  //box-shadow: inset 0 0 100px 1px rgba(0, 0, 0, 0.5);
  background-color: #9e9e9e;
}

//Error and Warning

.problemsContainer{
  position: absolute;
  top: 0;
  right: 0;
  z-index:1;
  background-color: rgba(255, 255, 255, 0.9);;
  border-bottom-left-radius: 5px;
  display:table;
  padding: 3px 5px 5px;
  box-shadow: 2px -1px 5px -2px black inset;

  .problem{
      float: left;
      height:18px;
      width:18px;
      border-radius:10px;
      margin: 0 5px;
      text-align: center;
      color: white;
      font-weight: bold;
      line-height: 1em;
      box-shadow: $box-boxshadow;

      &:hover{
        cursor:pointer;
        opacity:0.85;
      }
    
    &.warning{
      content:"!";
      background-color: rgb(254,192,65);
      border: 1px solid rgb(223,160,53);

      &:hover:after{
        content: "?";
      }
    }

    &.error{
      content:"x";
      border: 1px solid rgb(224,72,69);
      background-color: rgb(252,99,94);
      
      &:hover:after{
        content: "!";
      }
    }    

  }


}



