#main-header{
  color: #555;
  position: relative;
  z-index: 5;

  > .container{
    padding-left: 25px;
    padding-right: 25px;
  }

  .btn:hover{
    color: inherit;
  }

  .btn:focus{
    color: #444;
    background: transparent;
  }

  .btn-default{
    background: transparent;
    border-color: #dedede;
    color: #444;

    &:hover{
      background: #eee;
    }
  }

  .logo{
    display: block;
    float: left;
    padding: 5px 5px 5px 0px;

    img{
      display: block;
      height: 50px;
    }
  }

  .right-panel{
    float: right;
    margin: 15px 0px 0;

    .user-info{
      float: left;
      line-height: 30px;
      color: #5e717a;
      font-size: 12px;
      padding-right: 3px;

      strong{
        font-weight: 600;
      }

      .user-name{
        float: left;
        margin-right: 40px;
      }

      > div{
        float: left;
      }

      > div > a,
      > a{
        display: block;
        position: relative;
        float: left;
        text-align: center;
        margin: 0 -1px;

        span{
          -webkit-transition: all 0s;
          transition: all 0s;
          position: relative;
          width: 100%;
          font-size: 9px;
          font-weight: 600;
          line-height: 1em;
          z-index: 1;
          display: block;
          text-align: center;
          bottom: 7px;
          opacity: 0;
          color: #0a4f7a;
        }

        img{
          -webkit-transition: all 0.2s;
          transition: all 0.2s;
          opacity: 1;
          position: relative;
          z-index: 5;
          bottom: 0px;
          display: inline-block;
        }

        &:hover {
          text-decoration: none;
          img{
            bottom: 5px;
          }
          span{
            -webkit-transition: all 0.2s;
            transition: all 0.2s;
            transition-delay: 0.1s;
            opacity: 1;
            bottom: 0px;
          }
        }
      }
    }

    .btn-group{
      margin: 1px 7px 0 7px;
      float: left;
      box-shadow: none;
    }

    #language-selection{
      span{
        color: #0a4f7a;
        font-weight: 400;
      }
    }
  }

  .dropdown-menu{
    min-width: 0;
    > li > a{
      min-width: 0;
    }
  }

  .main-menu{
    background: $header-bg-color;
    clear: both;
    border-radius: 0;

    *{
      -webkit-transition: all .2s;
      transition: all .2s;
    }

    a{
      color: #fff;
    }

    ul{
      list-style: none;
      padding: 0;
      margin: 0;

      li{
        display: block;
        position: relative;
        float: left;
        padding: 0;
        margin: 0;

        
        &:hover{
          background: $main-menu-item-bg-hover
        }

      }
      

    }
    /* > div > ul > li + li{
      margin-left: 15px;
    } */

    > div > ul{
      margin-left: -10px;
      margin-right: -10px;

      > li a{
          height: 50px;
          line-height: 50px;
      }

      li{

        &.active{
          background: $main-menu-item-bg-active
        }

        &:hover{
          background: $main-menu-item-bg-hover
        }

        &.hovered{
          .sub-menu{
            -webkit-transition: all .2s;
            transition: all .2s;
            pointer-events: all;
            //display: block;
            opacity: 1;
          }
        }

        a{
          cursor: pointer;
          font-size: $menu-item-font-size;
          font-weight: 400;
          display: block;
          text-decoration: none;
          overflow: hidden;
          padding: 0 20px;
        }

      }
    }

    .sub-menu {
      pointer-events: none;
      -webkit-transition: all 0s;
      transition: all 0s;
      opacity: 0;
      min-width: 215px;
      position: absolute;
      list-style: none;
      background: $sub-menu-item-bg;
      box-shadow: 1px 1px 5px -1px rgba(0, 0, 0, 0.65), 0px -2px 3px -2px rgba(0, 0, 0, 0.30);

      li {
        clear: both;
        display: block;
        width: 100%;

        a {
          width: 100%;
          line-height: 20px;
          padding-top: 10px;
          padding-bottom: 10px;
          font-size: $menu-item-font-size;
          font-weight: normal;
          height: auto;
          display: block;

          .glyphicon {
            font-size: 10px;
            line-height: 20px;
          }
        }

        &.separate {
          box-shadow: 0px 1px #4e89a7 inset;
        }

        &:first-child a {
          box-shadow: none;
        }
        
        &.active {
          background: $sub-menu-item-bg-active;
        }

        ul {
          opacity: 0;
          pointer-events: none;
          position: absolute;
          top: 0;
          left: 100%;
          background: $sub-menu-item-bg-active;
          width: 200px;
        }

        &:hover {
          background: $sub-menu-item-bg-hover;

          > ul {
            opacity: 1;
            pointer-events: all;
          }
        }
      }
    }


  }

}