// --------------------- GODOO COLORS ---------------------
/* blue */
$godoo-main-color: #086391;
$godoo-main2-color: #008db0;
$godoo-main3-color: #e4f7fe;
/* magenta */
$godoo-second-color: #ff00ff;
$godoo-second2-color: #a000a0;
/* green */
$godoo-third-color: #a8d15d;
// --------------------------------------------------------

/* Variables */
$vin-blue: #5bb7db;
$vin-green: #60b044;
$vin-red: #ff0000;

$body-color: #fafafa;

$header-bg-color: #0a4f7a;

$sticky-bg-create: #c8e696;
$sticky-bg-edit: #d4f2ff;
$sticky-bg-default: #e9ecf0;
$sticky-color: #0a4f7a;

$menu-item-font-size: 15px;

$main-menu-item-bg: $godoo-main-color;
$main-menu-item-bg-hover: $godoo-main-color;
$main-menu-item-bg-active: $godoo-main-color;

$sub-menu-item-bg: $godoo-main-color;
$sub-menu-item-bg-hover: $godoo-main2-color;
$sub-menu-item-bg-active: #1ea5c7;

$table-striped-even-bg: #d4f2ff;
$table-font-color: #0a4f7a;

$btn-text-color: #fdfdfd;

/* Dashboard */
$transition-speed: .3s;

// BOXES
// --------------------------------------------------------
$box-border-color: #f4f4f4 !default;
$box-border-radius: 3px !default;
$box-footer-bg: #fff !default;
$box-boxshadow: 1px -1px 5px -2px black;
$box-padding: 10px !default;
